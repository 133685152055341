import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider} from "@auth0/auth0-react";
import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider} from '@mui/material';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: "#222222"
        }
    },
});

root.render(
    <React.StrictMode>
        <ThemeProvider theme={darkTheme}>
            <Auth0Provider
                domain="auth.dev.religionfy.com"
                clientId="f9SNpadDAnWs9cBZv7xiCDr51VsYUp1h"
                useRefreshTokensFallback={true}
                authorizationParams={{
                    audience: process.env.REACT_APP_AUDIENCE_AUTH0,
                    redirect_uri: window.location.origin,
                    scope: "openid profile email offline_access"
                }}
                useRefreshTokens={true}
                cacheLocation="localstorage"
            >
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </Auth0Provider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
