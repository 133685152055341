import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
import useApiClient from "../../hooks/useApiClient";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import toast from "react-hot-toast";
import {
    Alert, Autocomplete,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl,
    InputLabel, OutlinedInput, Select, SelectChangeEvent,
    TextField, Theme, useTheme
} from "@mui/material";
import {PreConfiguredBuyTokenModel} from "../../model/PreConfiguredBuyTokenModel";
import MenuItem from "@mui/material/MenuItem";
import {useWalletContext} from "../../hooks/use-wallet-context";
import {AddPreConfiguredBuyTokenModel, InstantBuyRequestModel} from "../../types/dto";
import {useRouterContext} from "../../hooks/use-router-context";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
        fontWeight:
            personName?.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export const routerOptionInitialState = {label: '', value: '', defaultPairing: ''}
const TokenPage = () => {
    const apiClient = useApiClient();
    const [preBuyList, setPreBuyList] = useState<any[]>([]);
    const [isPreBuyFormOpen, setIsPreBuyFormOpen] = useState(false);
    const [preBuyFormDetails, setPreBuyFormDetails] = useState<any>();
    const [walletToBuySelection, setWalletToBuySelection] = useState<string[]>([])
    const walletContext = useWalletContext();
    const [walletAddressList, setWalletAddressList] = useState<string[]>([]);
    const [selectedChain, setSelectedChain] = useState<{label: string, value: string, defaultPairing: string}>(routerOptionInitialState);
    const [selectedChainForRouter, setSelectedChainForRouter] = useState<any>({});
    const theme = useTheme();
    const routerContext = useRouterContext();
    const [routerOptions, setRouterOptions] = useState<any[]>([]);

    useEffect(() => {
        const walletList = walletContext.walletList.map(x => x.walletAddress);
        setWalletAddressList(walletList)
        refreshPreBuyList()
        setRouterOptions(routerContext.routerOptions.filter(x => x.isEnabled))
    }, [walletContext.walletList])

    const handleSelectedChain = (value: any) => {
        if (value == null){
            setSelectedChain(routerOptionInitialState);
            return;
        }
        setSelectedChain(value);
        setPreBuyFormDetails((prevState: any) => ({
            ...prevState,
            pairAddress: value.defaultPairing
        }));
    };

    const handleRouterOptions = (value: any) => {
        setSelectedChainForRouter(value);
        setSelectedChain(routerOptionInitialState);
        setRouterOptions(routerContext.routerOptions.filter(x => x.chainId == value.id && x.isEnabled))
    };
    const handleDelete = (createdTimestamp: number) => {
        apiClient.deletePreBuyList(createdTimestamp).then((x: any) => {
            if (!x) {
                toast.error("Pre Buy Configuration deletion failed")
                return;
            }
            refreshPreBuyList();
            toast.success("Pre Buy Configuration deleted")
        })
    }

    const refreshPreBuyList = () => {
        apiClient.getPreBuyList().then((x: any) => {
            setPreBuyList(x.list);
        })
    }

    const handleSelectChange = (event: any) => {
        const {
            target: {value},
        } = event;
        setWalletToBuySelection(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const onPreBuySubmitCreate = (event: any) => {
        event.preventDefault();

        if (preBuyFormDetails.createdTimestamp != null){
            const itemToUpdate : PreConfiguredBuyTokenModel = {
                chain: selectedChain.value,
                amountToBuy: preBuyFormDetails.amountToBuy,
                amountTokenToBuy: preBuyFormDetails.amountTokenToBuy,
                targetTokenAddress: preBuyFormDetails.targetTokenAddress,
                walletAddresses: walletToBuySelection,
                createdTimestamp: preBuyFormDetails.createdTimestamp,
                pairAddress: preBuyFormDetails.pairAddress,
                slippagePercentage: preBuyFormDetails.slippagePercentage
            }
            apiClient.updatePreBuyList(itemToUpdate).then(x => {
                if (!x) {
                    toast.error("Pre Buy Configuration update failed")
                    return;
                }

                refreshPreBuyList();
                toast.success("Pre Buy Configuration updated")
                handleCleanupAndCloseForm()
            })
            return;
        }

        const itemToBuy: AddPreConfiguredBuyTokenModel = {
            chain: selectedChain.value,
            amountToBuy: preBuyFormDetails.amountToBuy,
            amountTokenToBuy: preBuyFormDetails.amountTokenToBuy,
            targetTokenAddress: preBuyFormDetails.targetTokenAddress,
            walletAddresses: walletToBuySelection,
            pairAddress: preBuyFormDetails.pairAddress,
            slippagePercentage: preBuyFormDetails.slippagePercentage
        }

        apiClient.createPreBuyList(itemToBuy).then(x => {
            if (!x) {
                toast.error("Pre Buy Configuration creation failed")
                return;
            }
            refreshPreBuyList();
            toast.success("Pre Buy Configuration added")
            setIsPreBuyFormOpen(false)
        })
    }

    const onBuyPreConfiguredAction = (createdTimestamp: number) => {
        apiClient.buyPreBuyList(createdTimestamp).then(x => {
            if (!x) {
                toast.error("Buy token failed")
                return;
            }
            toast.success("Buy token succesfull")
        })
    }

    const onEditHandler = (createdTimestamp: number) => {
        var deepCopy = preBuyList.find(x => x.createdTimestamp == createdTimestamp);
        var deepCopyStringify = JSON.stringify(deepCopy);
        let editPreBuyList = JSON.parse(deepCopyStringify);
        setSelectedChain(routerContext.routerOptions.find(x => x.value == editPreBuyList.chain));
        let unknownWallets = [];
        if (editPreBuyList.walletAddresses != null){
            unknownWallets = editPreBuyList.walletAddresses.filter((x: any) => !walletContext.walletList.some(address => x == address.walletAddress));
            unknownWallets.forEach((unknown: any) => {
                var index = editPreBuyList.walletAddresses.indexOf(unknown);

                if (index !== -1) {
                    editPreBuyList.walletAddresses.splice(index, 1);
                }
            });
            setWalletToBuySelection(editPreBuyList.walletAddresses)
        }

        setPreBuyFormDetails({
            amountToBuy: editPreBuyList.amountToBuy,
            amountTokenToBuy: editPreBuyList.amountTokenToBuy,
            targetTokenAddress: editPreBuyList.targetTokenAddress,
            createdTimestamp: editPreBuyList.createdTimestamp,
            unknownWallets: unknownWallets,
            pairAddress: editPreBuyList.pairAddress ?? routerContext.routerOptions.find(x => x.value == editPreBuyList.chain)?.defaultPairing,
            slippagePercentage: editPreBuyList.slippagePercentage
        });
        setIsPreBuyFormOpen(true)
    }

    const onInstantBuyClick = () => {
        var instantBuyRequest : InstantBuyRequestModel = {
            chain: selectedChain.value,
            amountToBuy: preBuyFormDetails.amountToBuy,
            amountTokenToBuy: preBuyFormDetails.amountTokenToBuy,
            targetTokenAddress: preBuyFormDetails.targetTokenAddress,
            walletAddresses: walletToBuySelection,
            pairAddress: preBuyFormDetails.pairAddress,
            slippagePercentage: preBuyFormDetails.slippagePercentage
        }

        apiClient.instantBuy(instantBuyRequest).then(x => {
            if (!x) {
                toast.error("Instant buy token failed")
                return;
            }
            toast.success("Instant buy token succesfull")
            handleCleanupAndCloseForm()
        })
    }

    const onChangePreBuy = async (event: any) => {
        setPreBuyFormDetails({
            ...preBuyFormDetails,
            [event.target.name]: event.target.value
        })
    }

    const handleCleanupAndCloseForm = () => {
        setPreBuyFormDetails({})
        setSelectedChain(routerOptionInitialState);
        setSelectedChainForRouter({})
        setIsPreBuyFormOpen(false)
    }

    return <Container>
        <Box style={{marginTop: 20}} component="span"
             display="flex"
             justifyContent="left"
             alignItems="center">
            <Button variant={"contained"} onClick={() => setIsPreBuyFormOpen(true)}>Pre-Configure
                Buy</Button>
        </Box>

        <Box style={{marginTop: 20}}>
            <Typography align={"left"}>Pre Configure Buy List</Typography>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Token Address</TableCell>
                            <TableCell align="right">Wallet To Buy</TableCell>
                            <TableCell align="right">Chain</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            <TableCell align="right">Amount Token To Buy</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {preBuyList.map((token: any) => (
                            <TableRow
                                key={token.createdTimestamp}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                style={token.walletAddresses?.filter((x: any) => !walletContext.walletList.some(address => x == address.walletAddress)) > 0  ? {backgroundColor: 'red'} : {}}
                            >
                                <TableCell align="left">{token.targetTokenAddress}</TableCell>
                                <TableCell align="right">{token.walletAddresses?.map((x: string) => <Chip label={x} />)}</TableCell>
                                <TableCell align="right">{routerContext.routerList.find(x => x.id == token.chain)?.name}</TableCell>
                                <TableCell align="right">{token.amountToBuy}</TableCell>
                                <TableCell align="right">{token.amountTokenToBuy}</TableCell>
                                <TableCell align="right">
                                    <Button onClick={() => onBuyPreConfiguredAction(token.createdTimestamp)} style={{backgroundColor: 'green'}}>Buy</Button>
                                    <Button onClick={() => onEditHandler(token.createdTimestamp)} variant={"contained"}>Edit</Button>
                                    <Button onClick={() => handleDelete(token.createdTimestamp)} style={{backgroundColor: 'red'}} variant={"contained"}>Delete</Button>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>

        <Dialog open={isPreBuyFormOpen} onClose={() => setIsPreBuyFormOpen(false)}>
            <form onSubmit={onPreBuySubmitCreate}>

                <DialogTitle>Add Pre Buy Settings</DialogTitle>
                <DialogContent>
                    {(preBuyFormDetails?.unknownWallets != undefined && preBuyFormDetails.unknownWallets > 0) && <Alert severity="error">There are some unknown wallet int his pre buy settings, please save to sync wallet !!<br/>
                        Unknown Wallet List
                        <ul>
                            {preBuyFormDetails.unknownWallets.map((address: string) => <li>{address}</li>)}
                        </ul>
                    </Alert>}
                    <DialogContentText>
                        Setup your pre configured buy settings here for easier access when token launch !! 🚀
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="targetTokenAddress"
                        name="targetTokenAddress"
                        label="Target Address"
                        type="text"
                        fullWidth
                        onChange={onChangePreBuy}
                        value={preBuyFormDetails?.targetTokenAddress}
                        variant="standard"
                    />

                    <FormControl fullWidth={true} sx={{m: 1, minWidth: 80}}>
                        <Autocomplete
                            value={selectedChainForRouter}
                            onChange={(event, newValue) => handleRouterOptions(newValue)}
                            id="chain-select"
                            options={routerContext.chainList.filter(x => x.isEnabled)}
                            getOptionLabel={(option) => {
                                return option?.name ?? ""}}
                            renderOption={(props, option) =>
                                <li {...props}>{option.name}</li>}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Chain" />}
                        />
                    </FormControl>

                    <FormControl fullWidth={true} sx={{m: 1, minWidth: 80}}>
                        <Autocomplete
                            value={selectedChain}
                            onChange={(event, newValue) => handleSelectedChain(newValue)}
                            id="router-select"
                            options={routerOptions}
                            renderOption={(props, option) =>
                                <li {...props}>{option.label}</li>}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Router" />}
                        />
                    </FormControl>
                    <br/>
                    <FormControl fullWidth={true} sx={{m: 1, minWidth: 80}}>
                        <InputLabel id="wallet-address-select">Wallet Address</InputLabel>
                        <Select
                            labelId="wallet-address-select"
                            id="select-multiple-wallet-address"
                            multiple
                            fullWidth={true}
                            value={walletToBuySelection}
                            onChange={handleSelectChange}
                            input={<OutlinedInput id="select-multiple-wallet-address" label="Wallet Address"/>}
                            renderValue={(selected) => (
                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value}/>
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {walletAddressList.map((address) => (
                                <MenuItem
                                    key={address}
                                    value={address}
                                    style={getStyles(address, walletToBuySelection, theme)}
                                >
                                    {address}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="pairAddress"
                        name="pairAddress"
                        label="Pair Address"
                        type="text"
                        fullWidth
                        onChange={onChangePreBuy}
                        value={preBuyFormDetails?.pairAddress ?? ''}
                        variant="standard"
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="amountToBuy"
                        name="amountToBuy"
                        label="Amount"
                        type="text"
                        onChange={onChangePreBuy}
                        value={preBuyFormDetails?.amountToBuy}
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="amountTokenToBuy"
                        name="amountTokenToBuy"
                        label="Amount Token To Buy"
                        type="text"
                        onChange={onChangePreBuy}
                        value={preBuyFormDetails?.amountTokenToBuy}
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="slippagePercentage"
                        name="slippagePercentage"
                        label="Slippage Percentage"
                        type="number"
                        onChange={onChangePreBuy}
                        value={preBuyFormDetails?.slippagePercentage}
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsPreBuyFormOpen(false)}>Cancel</Button>
                    <Button type={"submit"}>Save</Button>
                    <Button onClick={onInstantBuyClick}>Buy now</Button>
                </DialogActions>
            </form>
        </Dialog>
    </Container>
}

export default TokenPage