import {useAuth0} from "@auth0/auth0-react";
import axios from 'axios';
import {PreConfiguredBuyTokenModel} from "../model/PreConfiguredBuyTokenModel";
import {
    AddPreConfiguredBuyTokenModel,
    AddWalletDetailsRequest,
    ChainForm,
    ChainList,
    InstantBuyRequestModel, RouterForm, RouterList, SnipingBotSettings, SnipingBotSettingsRequest,
    SnipingListAddRequest,
    SnipingListDto,
    SnipingMethod,
    TransactionHistory,
    UpdateWalletDetailsRequest
} from "../types/dto";
import {Router} from "react-router";

const useApiClient = (): {
    getTokenSummary: () => any,
    getSnipingList: () => Promise<SnipingListDto[]>,
    getPreBuyList: () => any,
    getWalletList: () => any,
    addWallet: (item: AddWalletDetailsRequest) => Promise<any>,
    updateWallet: (item: UpdateWalletDetailsRequest) => Promise<any>,
    deleteWallet: (createdTimestamp: number) => Promise<any>,
    deletePreBuyList: (createdTimestamp: number) => Promise<any>,
    buyPreBuyList: (createdTimestamp: number) => Promise<boolean>,
    createPreBuyList: (item: AddPreConfiguredBuyTokenModel) => Promise<any>,
    updatePreBuyList: (item: AddPreConfiguredBuyTokenModel) => Promise<any>,
    instantBuy: (item: InstantBuyRequestModel) => Promise<boolean>,
    addSnipingMethod: (item: SnipingMethod) => Promise<boolean>,
    addSnipingList: (item: SnipingListAddRequest) => Promise<boolean>,
    syncSnipingList: () => Promise<boolean>,
    deleteSnipingList: (createdTimestamp: number) => Promise<any>,
    getTransactionList: () => Promise<any>,
    getSnipingMethodOptions: () => Promise<SnipingMethod[]>,
    addUpdateChain: (request: ChainForm) => Promise<boolean>,
    deleteChain: (id: string) => Promise<boolean>,
    getChainList: () => Promise<ChainList[]>,
    addUpdateRouter: (request: RouterForm) => Promise<boolean>,
    deleteRouter: (id: string) => Promise<boolean>,
    getRouterList: () => Promise<RouterList[]>,
    getBotSettingsList: () => Promise<SnipingBotSettings[]>,
    saveBotSettings: (request: SnipingBotSettingsRequest) => Promise<boolean>
    updateRouterEnabledSettings: (isEnabled: boolean, routerId: string) => Promise<boolean>
    updateChainEnabledSettings: (isEnabled: boolean, chainId: string) => Promise<boolean>
} => {
    const auth = useAuth0();

    const getAccessToken = () => {
        return auth.getAccessTokenSilently({
            authorizationParams: {
                scope: "openid"
            }
        }).then((x: any) => {
            return x;
        })
    }
    const getTokenSummary = () => {
        const response = axios.get(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Customer/tokens/summary`).then(x => {
            return x.data
        })

        return response;
    }

    const getSnipingList = async (): Promise<SnipingListDto[]> => {
        if (!auth.isAuthenticated) {
            return [];
        }
        const accessToken = await getAccessToken();
        const response = axios.get(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Snipe/list`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return x.data.snipeList
        })

        return response;
    }

    const getWalletList = async () => {
        if (!auth.isAuthenticated) {
            return;
        }
        const accessToken = await getAccessToken();
        const response = axios.get(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Wallet/list`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return x.data
        })

        return response;
    }

    const getTransactionList = async () => {
        if (!auth.isAuthenticated) {
            return;
        }
        const accessToken = await getAccessToken();
        const response = axios.get(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Transaction/list`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return x.data
        })

        return response;
    }

    const getPreBuyList = async () => {
        if (!auth.isAuthenticated) {
            return;
        }
        const accessToken = await getAccessToken();
        const response = axios.get(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Configuration/customer/pre-buy/list`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return x.data
        })

        return response;
    }

    const deletePreBuyList = async (createdTimestamp: number): Promise<any> => {
        if (!auth.isAuthenticated) {
            return;
        }
        const accessToken = await getAccessToken();
        const response = axios.delete(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Configuration/customer/pre-buy?Timestamp=${createdTimestamp}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            return false;
        })

        return response;
    }

    const createPreBuyList = async (item: AddPreConfiguredBuyTokenModel) => {
        if (!auth.isAuthenticated) {
            return;
        }
        const accessToken = await getAccessToken();
        const response = axios.post(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Configuration/customer/pre-buy`, item, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            return false;
        })

        return response;
    }

    const updatePreBuyList = async (item: AddPreConfiguredBuyTokenModel) => {
        if (!auth.isAuthenticated) {
            return;
        }
        const accessToken = await getAccessToken();
        const response = axios.put(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Configuration/customer/pre-buy`, item, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            return false;
        })

        return response;
    }

    const buyPreBuyList = async (createdTimestamp: number): Promise<boolean> => {
        if (!auth.isAuthenticated) {
            return false;
        }

        const accessToken = await getAccessToken();
        const response = axios.post(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Transaction/buy/pre-configured?createdTimestamp=${createdTimestamp}`, null, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            return false;
        })

        return response;
    }

    const addWallet = async (item: AddWalletDetailsRequest) => {
        if (!auth.isAuthenticated) {
            return;
        }
        const accessToken = await getAccessToken();
        const response = axios.post(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Wallet`, item, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            console.error(err)
            return false;
        })

        return response;
    }

    const updateWallet = async (item: UpdateWalletDetailsRequest) => {
        if (!auth.isAuthenticated) {
            return;
        }
        const accessToken = await getAccessToken();
        const response = axios.put(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Wallet`, item, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            console.error(err)
            return false;
        })

        return response;
    }

    const instantBuy = async (item: InstantBuyRequestModel): Promise<boolean> => {
        if (!auth.isAuthenticated) {
            return false;
        }
        const accessToken = await getAccessToken();
        const response = axios.post(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Transaction/buy/instant`, item, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            console.error(err)
            return false;
        })

        return response;
    }

    const deleteWallet = async (createdTimestamp: number) => {
        if (!auth.isAuthenticated) {
            return;
        }
        const accessToken = await getAccessToken();
        const response = axios.delete(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Wallet?createdTimestamp=${createdTimestamp}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            console.error(err)
            return false;
        })

        return response;
    }

    const getSnipingMethodOptions = async (): Promise<SnipingMethod[]> => {
        if (!auth.isAuthenticated) {
            return [];
        }
        const accessToken = await getAccessToken();
        const response = axios.get<SnipingMethod[]>(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Snipe/method/list`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return x.data;
        }).catch(err => {
            console.error(err)
            return [];
        })

        return response;
    }

    const addSnipingMethod = async (snipingMethod: SnipingMethod): Promise<boolean> => {
        if (!auth.isAuthenticated) {
            return false;
        }
        const accessToken = await getAccessToken();
        const response = axios.post<SnipingMethod>(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Snipe/method`, snipingMethod, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            console.error(err)
            return false;
        })

        return response;
    }

    const addSnipingList = async (addSnipingRequest: SnipingListAddRequest): Promise<boolean> => {
        if (!auth.isAuthenticated) {
            return false;
        }
        const accessToken = await getAccessToken();
        const response = axios.post<SnipingMethod>(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Snipe`, addSnipingRequest, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            console.error(err)
            return false;
        })

        return response;
    }

    const syncSnipingList = async (): Promise<boolean> => {
        if (!auth.isAuthenticated) {
            return false;
        }
        const accessToken = await getAccessToken();
        const response = axios.get(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Configuration/snipe/sync`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            console.error(err)
            return false;
        })

        return response;
    }


    const deleteSnipingList = async (createdTimestamp: number): Promise<any> => {
        if (!auth.isAuthenticated) {
            return;
        }
        const accessToken = await getAccessToken();
        const response = axios.delete(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Snipe?Timestamp=${createdTimestamp}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            return false;
        })

        return response;
    }

    const addUpdateChain = async (request: ChainForm): Promise<boolean> => {
        if (!auth.isAuthenticated) {
            return false;
        }
        const accessToken = await getAccessToken();
        const response = axios.post<ChainForm>(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Configuration/chain`, request, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            console.error(err)
            return false;
        })

        return response;
    }

    const deleteChain = async (id: string): Promise<boolean> => {
        if (!auth.isAuthenticated) {
            return false;
        }
        const accessToken = await getAccessToken();
        const response = axios.delete(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Configuration/chain/${id}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            return false;
        })

        return response;
    }

    const getChainList = async (): Promise<ChainList[]> => {
        if (!auth.isAuthenticated) {
            return [];
        }
        const accessToken = await getAccessToken();
        const response = axios.get<ChainList[]>(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Configuration/chain/list`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return x.data;
        }).catch(err => {
            console.error(err)
            return [];
        })

        return response;
    }

    const addUpdateRouter = async (request: RouterForm): Promise<boolean> => {
        if (!auth.isAuthenticated) {
            return false;
        }
        const accessToken = await getAccessToken();
        const response = axios.post<ChainForm>(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Configuration/router`, request, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            console.error(err)
            return false;
        })

        return response;
    }

    const deleteRouter = async (id: string): Promise<boolean> => {
        if (!auth.isAuthenticated) {
            return false;
        }
        const accessToken = await getAccessToken();
        const response = axios.delete(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Configuration/router/${id}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            return false;
        })

        return response;
    }

    const getRouterList = async (): Promise<RouterList[]> => {
        if (!auth.isAuthenticated) {
            return [];
        }
        const accessToken = await getAccessToken();
        const response = axios.get<RouterList[]>(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Configuration/router/list`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return x.data;
        }).catch(err => {
            console.error(err)
            return [];
        })

        return response;
    }

    const getBotSettingsList = async (): Promise<SnipingBotSettings[]> => {
        if (!auth.isAuthenticated) {
            return [];
        }
        const accessToken = await getAccessToken();
        const response = axios.get<SnipingBotSettings[]>(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Configuration/bot/list`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return x.data;
        }).catch(err => {
            console.error(err)
            return [];
        })

        return response;
    }

    const saveBotSettings = async (request: SnipingBotSettingsRequest): Promise<boolean> => {
        if (!auth.isAuthenticated) {
            return false;
        }
        const accessToken = await getAccessToken();
        const response = axios.put<SnipingBotSettingsRequest>(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Configuration/bot`, request, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            console.error(err)
            return false;
        })

        return response;
    }

    const updateChainEnabledSettings = async (isEnabled: boolean, id: string): Promise<boolean> => {
        if (!auth.isAuthenticated) {
            return false;
        }
        const accessToken = await getAccessToken();
        const response = axios.put<SnipingBotSettingsRequest>(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Configuration/chain/enabled`, {
            IsEnabled: isEnabled,
            Id: id
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            console.error(err)
            return false;
        })

        return response;
    }

    const updateRouterEnabledSettings = async (isEnabled: boolean, id: string): Promise<boolean> => {
        if (!auth.isAuthenticated) {
            return false;
        }
        const accessToken = await getAccessToken();
        const response = axios.put<SnipingBotSettingsRequest>(`${process.env.REACT_APP_PRIMONTER_API_BASE_URL}/Configuration/router/enabled`, {
            IsEnabled: isEnabled,
            Id: id
        }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(x => {
            return true;
        }).catch(err => {
            console.error(err)
            return false;
        })

        return response;
    }


    return {
        getTokenSummary,
        getSnipingList,
        getPreBuyList,
        deletePreBuyList,
        createPreBuyList,
        getWalletList,
        addWallet,
        deleteWallet,
        buyPreBuyList,
        updateWallet,
        updatePreBuyList,
        getTransactionList,
        instantBuy,
        getSnipingMethodOptions,
        addSnipingMethod,
        addSnipingList,
        deleteSnipingList,
        syncSnipingList,
        addUpdateChain,
        deleteChain,
        getChainList,
        getRouterList,
        deleteRouter,
        addUpdateRouter,
        getBotSettingsList,
        saveBotSettings,
        updateChainEnabledSettings,
        updateRouterEnabledSettings
    }
}

export default useApiClient