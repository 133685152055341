import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Container from "@mui/material/Container";
import * as React from "react";
import {useEffect, useState} from "react";
import useApiClient from "../../hooks/useApiClient";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import {useRouterContext} from "../../hooks/use-router-context";
const TransactionsPage = () => {
    const [transactionList, setTransactionList] = useState<any[]>([]);

    const apiClient = useApiClient();
    const routerContext = useRouterContext();

    useEffect(() => {
        apiClient.getTransactionList().then(x => {
            setTransactionList(x.transactionList)
        })
    }, [])

    return <Container maxWidth={false}>
        <Box><h2>Transaction History</h2></Box>
        <Box style={{marginTop: 20}} component="span"
             display="flex"
             justifyContent="left"
             alignItems="center">
        </Box>

        <Box style={{marginTop: 20}}>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Wallet Address</TableCell>
                            <TableCell align="right">Target Token Address</TableCell>
                            <TableCell align="right">Action</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            <TableCell align="right">Chain</TableCell>
                            <TableCell align="right">Status</TableCell>
                            <TableCell align="right">Note</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactionList.map((token: any) => (
                            <TableRow
                                key={token.createdTimestamp}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {token.walletAddress}
                                </TableCell>
                                <TableCell align="right">{token.targetTokenAddress}</TableCell>
                                <TableCell align="right">{token.action}</TableCell>
                                <TableCell align="right">{token.amount}</TableCell>
                                <TableCell align="right">{routerContext.routerList.find(x => x.routerAddress == token.chain)?.name}</TableCell>
                                <TableCell align="right">{token.isSuccess ? <CheckBoxIcon/> : <SmsFailedIcon/>}</TableCell>
                                <TableCell align="right">{token.note}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </Container>
}

export default TransactionsPage