import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import Container from "@mui/material/Container";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useWalletContext} from "../../hooks/use-wallet-context";
import toast from "react-hot-toast";
import {WalletDetails} from "../../types/dto";

const defaultWalletFormState : WalletDetails = {
    walletName: '',
    walletAddress: '',
    privateKey: '',
    createdTimestamp: 0,
    customerId: ''
}
const WalletPage = () => {

    const walletContext = useWalletContext();
    const [isWalletAddFormOpen, setisWalletAddFormOpen] = useState(false);
    const [walletFormDetails, setWalletFormDetails] =  useState<WalletDetails>({
        walletName: '',
        walletAddress: '',
        privateKey: '',
        createdTimestamp: 0,
        customerId: ''
    });
    const onSubmitForm = async (event: any) => {
        event.preventDefault();

        if (walletFormDetails.createdTimestamp != 0) {
            const editResponse = await onSubmitEditForm(walletFormDetails);
            if (!editResponse){
                toast.error("Failed to edit wallet")
                return;
            }

            toast.success("Wallet succesfully edited")
            walletContext.refreshWalletList();
            cleanUpAndCloseForm()
            return;
        }

        const walletAddress = walletFormDetails.walletAddress;
        const privateKey = walletFormDetails.privateKey;
        const walletName = walletFormDetails.walletName;

        const response = await walletContext.addWallet(privateKey, walletAddress, walletName);
        if (!response){
            toast.error("Failed to add wallet")
            return;
        }

        toast.success("Wallet succesfully added")
        walletContext.refreshWalletList();
        cleanUpAndCloseForm()
        return;
    }

    const onSubmitEditForm = async (walletDetails: WalletDetails) : Promise<boolean> => {
        return await walletContext.editWallet(walletDetails);
    }

    const onChangeWallet = async (event: any) => {

        setWalletFormDetails({
            ...walletFormDetails,
            [event.target.name]: event.target.value
        })
    }

    const cleanUpAndCloseForm = () => {
        setWalletFormDetails(defaultWalletFormState);
        setisWalletAddFormOpen(false)
    }

    const onDeleteWallet = async (createdTimestamp: number) => {
        const response = await walletContext.removeWallet(createdTimestamp)
        if (!response){
            toast.error("Failed to delete wallet")
            return;
        }

        toast.success("Wallet succesfully deleted")
        walletContext.refreshWalletList();
        return;
    }

    const onEditWallet = async (walletDetails: WalletDetails) => {
        setWalletFormDetails(walletDetails);
        setisWalletAddFormOpen(true);
    }

    return <Container>
        <Box><h2>Wallet List</h2></Box>
        <Box style={{marginTop: 20}} component="span"
             display="flex"
             justifyContent="left"
             alignItems="center">
            <Button style={{marginLeft: 20}} variant={"contained"} onClick={() => setisWalletAddFormOpen(true)}>Add
                Wallet</Button>
        </Box>

        <Box style={{marginTop: 20}}>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Wallet Name</TableCell>
                            <TableCell>Wallet Address</TableCell>
                            <TableCell align="right">Private Key</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {walletContext.walletList.map((token: any) => (
                            <TableRow
                                key={token.createdTimestamp}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {token.walletName}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {token.walletAddress}
                                </TableCell>
                                <TableCell align="right">{token.privateKey}</TableCell>
                                <TableCell align="right">
                                    <Button style={{background: "red"}}
                                            onClick={() => onDeleteWallet(token.createdTimestamp)}
                                            variant={"contained"}>Delete</Button> <br/><br/>
                                    <Button onClick={() => onEditWallet(token)}
                                            variant={"contained"}>Edit</Button> <br/><br/>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>

        <Dialog open={isWalletAddFormOpen} onClose={() => cleanUpAndCloseForm()}>
            <form onSubmit={onSubmitForm}>
                <DialogTitle>Add Wallet</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Setup your wallet to buy token !! 🚀
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="walletName"
                        name="walletName"
                        label="Wallet Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={onChangeWallet}
                        value={walletFormDetails?.walletName}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="wallet-address"
                        name="walletAddress"
                        label="Wallet Address"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={onChangeWallet}
                        value={walletFormDetails?.walletAddress}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="pk"
                        disabled={walletFormDetails.createdTimestamp > 0}
                        name="privateKey"
                        label="Private Key"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={onChangeWallet}
                        value={walletFormDetails?.privateKey}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => cleanUpAndCloseForm()}>Cancel</Button>
                    <Button type={"submit"}>Save</Button>
                </DialogActions>
            </form>
        </Dialog>
    </Container>
}

export default WalletPage