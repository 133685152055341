import {useEffect, useState} from "react";
import {WalletContext} from "../../context";
import useApiClient from "../../hooks/useApiClient";
import {AddWalletDetailsRequest, WalletDetails} from "../../types/dto";

export const WalletContextProvider = ({children}: any) => {
    const [walletList, setWalletList] = useState([]);
    const apiClient = useApiClient();

    useEffect(() => {
        refreshWallet()
    }, []);

    const refreshWallet = () => {
        apiClient.getWalletList().then((x: any) => {
            setWalletList(x);
        });
    }
    const addWallet = (privateKey: string, walletAddress: string, walletName: string) : Promise<boolean> => {
        const walletToCreate: AddWalletDetailsRequest = {
            privateKey: privateKey,
            walletAddress: walletAddress,
            walletName: walletName
        }

        return apiClient.addWallet(walletToCreate).then((isSuccess: boolean) => {
            return isSuccess;
        });
    }

    const editWallet = (wallet: WalletDetails) : Promise<boolean> => {
        return apiClient.updateWallet(wallet).then((isSuccess: boolean) => {
            return isSuccess;
        });
    }

    const removeWallet = (createdTimestamp: number) : Promise<boolean> => {
        return apiClient.deleteWallet(createdTimestamp).then((isSuccess: boolean) => {
            return isSuccess;
        });
    }


    return <WalletContext.Provider value={{
        walletList: walletList,
        addWallet: addWallet,
        removeWallet: removeWallet,
        refreshWalletList: refreshWallet,
        editWallet
    }}>{children}</WalletContext.Provider>}