import React, {useState} from "react";
import Box from "@mui/material/Box";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent, Switch,
    Table,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {ExpandMore} from "@mui/icons-material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import {ChainForm, ChainList, RouterForm, RouterList, SnipingBotSettings} from "../../types/dto";
import useApiClient from "../../hooks/useApiClient";
import toast from "react-hot-toast";
import MenuItem from "@mui/material/MenuItem";
import {useRouterContext} from "../../hooks/use-router-context";
import Paper from "@mui/material/Paper";
import {BotSettingsAccordion} from "./bot-settings-accordion";

export const SwapVersion = {
    V2: "V2",
    V3: "V3"
}

const SettingsPage = () => {
    const routerContext = useRouterContext();

    const [isChainDialogOpen, setIsChainDialogOpen] = useState(false);
    const [isRouterDialogOpen, setIsRouterDialogOpen] = useState(false);

    const [chainForm, setChainForm] = useState<ChainForm>({
        rpcUrl: '',
        name: '',
        id: null,
        nativeTokenAddress: '',
        txType: 0,
        gas: 0,
        maxFeePerGas: 0,
        maxPriorityFeePerGas: 0,
        gasPrice: "0",
        isEnabled: false
    });
    const [routerForm, setRouterForm] = useState<RouterForm>({
        chainId: '',
        name: '',
        routerAddress: '',
        id: null,
        swapVersion: SwapVersion.V2,
        isEnabled: false
    });
    const apiClient = useApiClient();

    const setChainOnChangeForm = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setChainForm({
            ...chainForm,
            [ev.target.name]: ev.target.value
        })
    }

    const setRouterOnChangeForm = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setRouterForm({
            ...routerForm,
            [ev.target.name]: ev.target.value
        })
    }

    const deleteChain = async (id: string) => {
        const response = await apiClient.deleteChain(id);
        if (!response) {
            toast.error("Failed to delete chain")
            return;
        }
        routerContext.refreshChain()
        toast.success("Chain succesfully deleted")
    }

    const editChain = async (chain: ChainList) => {
        setChainForm({
            id: chain.id,
            gasPrice: chain.gasPrice,
            gas: chain.gas,
            maxPriorityFeePerGas: chain.maxPriorityFeePerGas,
            maxFeePerGas: chain.maxFeePerGas,
            txType: chain.txType,
            name: chain.name,
            nativeTokenAddress: chain.nativeTokenAddress,
            rpcUrl: chain.rpcUrl,
            wsUrl: chain.wsUrl,
            isEnabled: chain.isEnabled
        });
        setIsChainDialogOpen(true)
    }
    const editRouter = async (router: RouterList) => {
        setRouterForm({
            id: router.id,
            routerAddress: router.routerAddress,
            name: router.name,
            chainId: router.chainId,
            swapVersion: router.swapVersion,
            isEnabled: router.isEnabled
        });
        setIsRouterDialogOpen(true)
    }

    const deleteRouter = async (id: string) => {
        const response = await apiClient.deleteRouter(id);
        if (!response) {
            toast.error("Failed to delete router")
            return;
        }
        routerContext.refreshRouter(routerContext.chainList)
        toast.success("Router succesfully deleted")
    }

    const saveChain = async () => {
        const response = await apiClient.addUpdateChain(chainForm);
        if (!response) {
            toast.error("Failed to add chain")
            return;
        }
        routerContext.refreshChain()
        setIsChainDialogOpen(false);
        toast.success("Chain succesfully added")
    }

    const saveRouter = async () => {
        const response = await apiClient.addUpdateRouter(routerForm);
        if (!response) {
            toast.error("Failed to add router")
            return;
        }
        routerContext.refreshRouter(routerContext.chainList)
        setIsRouterDialogOpen(false);
        toast.success("Router succesfully added")
    }

    const handleSelectedChainInRouter = (event: SelectChangeEvent) => {
        setRouterForm((prevState: any) => ({
            ...prevState,
            chainId: routerContext.chainList.find(x => x.id == event.target.value)?.id
        }));
    };

    const handleSelect = (event: SelectChangeEvent) => {
        setRouterForm((prevState: any) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    };

    const handleEnableSwitchChain = (event: React.ChangeEvent<HTMLInputElement>, chainId: string) => {
        apiClient.updateChainEnabledSettings(event.target.checked, chainId).then(x => {
            routerContext.refreshChain()
        })
    }

    const handleEnableSwitchRouter = (event: React.ChangeEvent<HTMLInputElement>, routerId: string) => {
        apiClient.updateRouterEnabledSettings(event.target.checked, routerId).then(x => {
            routerContext.refreshRouter(routerContext.chainList)
        })
    }

    // @ts-ignore
    return <Container maxWidth={false}>
        <Box><h2>Settings</h2></Box>

        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Chain</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box component="span"
                     display="flex"
                     justifyContent="left"
                     alignItems="center">
                    <Button variant={"contained"} onClick={() => setIsChainDialogOpen(true)}>Add</Button>
                </Box>
                <Dialog open={isChainDialogOpen} onClose={() => setIsChainDialogOpen(false)}>
                    <DialogTitle>Add Chain</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Add Chain
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            name="name"
                            label="Chain Name"
                            type="text"
                            fullWidth
                            value={chainForm.name}
                            variant="standard"
                            onChange={setChainOnChangeForm}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="rpcUrl"
                            name="rpcUrl"
                            label="RPC Url"
                            type="text"
                            fullWidth
                            value={chainForm.rpcUrl}
                            variant="standard"
                            onChange={setChainOnChangeForm}
                        />

                        <TextField
                            autoFocus
                            margin="dense"
                            id="wsUrl"
                            name="wsUrl"
                            label="Websocket Url"
                            type="text"
                            fullWidth
                            value={chainForm.wsUrl}
                            variant="standard"
                            onChange={setChainOnChangeForm}
                        />

                        <TextField
                            autoFocus
                            margin="dense"
                            id="nativeTokenAddress"
                            name="nativeTokenAddress"
                            label="Native Token Address"
                            type="text"
                            fullWidth
                            value={chainForm.nativeTokenAddress}
                            variant="standard"
                            onChange={setChainOnChangeForm}
                        />

                        <TextField
                            autoFocus
                            margin="dense"
                            id="txType"
                            name="txType"
                            label="Tx Type"
                            type="number"
                            fullWidth
                            value={chainForm.txType}
                            variant="standard"
                            onChange={setChainOnChangeForm}
                        />

                        <TextField
                            autoFocus
                            margin="dense"
                            id="gas"
                            name="gas"
                            label="Gas Limit"
                            type="number"
                            fullWidth
                            value={chainForm.gas}
                            variant="standard"
                            onChange={setChainOnChangeForm}
                        />

                        <TextField
                            autoFocus
                            margin="dense"
                            id="gasPrice"
                            name="gasPrice"
                            label="Gas Price"
                            type="number"
                            fullWidth
                            value={chainForm.gasPrice}
                            variant="standard"
                            onChange={setChainOnChangeForm}
                        />

                        <TextField
                            autoFocus
                            margin="dense"
                            id="maxPriorityFeePerGas"
                            name="maxPriorityFeePerGas"
                            label="Max Priority Fee Per Gas"
                            type="number"
                            fullWidth
                            value={chainForm.maxPriorityFeePerGas}
                            variant="standard"
                            onChange={setChainOnChangeForm}
                        />

                        <TextField
                            autoFocus
                            margin="dense"
                            id="maxFeePerGas"
                            name="maxFeePerGas"
                            label="Max Fee Per Gas"
                            type="number"
                            fullWidth
                            value={chainForm.maxFeePerGas}
                            variant="standard"
                            onChange={setChainOnChangeForm}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsChainDialogOpen(false)}>Cancel</Button>
                        <Button onClick={() => saveChain()}>Save</Button>
                    </DialogActions>
                </Dialog>
                <Paper>
                    <Table className={"accordion-table"} style={{overflow: "scroll" }}>
                        <TableHead>
                            <TableRow style={{wordWrap: "break-word"}}>
                                <TableCell>Chain Name</TableCell>
                                <TableCell>Chain RPC Url</TableCell>
                                <TableCell>Chain WS Url</TableCell>
                                <TableCell>Chain Native Token Address</TableCell>
                                <TableCell>Chain Tx Type</TableCell>
                                <TableCell>Chain Gas Limit</TableCell>
                                <TableCell>Chain Gas Price</TableCell>
                                <TableCell>Chain Max Priority Fee Per Gas</TableCell>
                                <TableCell>Chain Max Fee Per Gas</TableCell>
                                <TableCell>Enabled</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {routerContext.chainList.map((chain: any) => (
                                <TableRow
                                    key={chain.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {chain.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {chain.rpcUrl}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {chain.wsUrl}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {chain.nativeTokenAddress}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {chain.txType}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {chain.gas}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {chain.gasPrice}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {chain.maxPriorityFeePerGas}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {chain.maxFeePerGas}
                                    </TableCell>
                                    <TableCell>
                                        <Switch onChange={(event) => handleEnableSwitchChain(event, chain.id)} checked={chain.isEnabled}/></TableCell>
                                    <TableCell>
                                        <Button variant={"contained"} style={{backgroundColor: "green"}}
                                                onClick={() => editChain(chain)}>Edit</Button>
                                        <Button variant={"contained"} style={{backgroundColor: "red"}}
                                                onClick={() => deleteChain(chain.id)}>Delete</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Router</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box component="span"
                     display="flex"
                     justifyContent="left"
                     alignItems="center">
                    <Button variant={"contained"} onClick={() => setIsRouterDialogOpen(true)}>Add</Button>
                </Box>
                <Dialog open={isRouterDialogOpen} onClose={() => setIsRouterDialogOpen(false)}>
                    <DialogTitle>Add Router</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Add Router
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            name="name"
                            label="Router Name"
                            type="text"
                            fullWidth
                            value={routerForm.name}
                            variant="standard"
                            onChange={setRouterOnChangeForm}
                        />

                        <TextField
                            autoFocus
                            margin="dense"
                            id="routerAddress"
                            name="routerAddress"
                            label="Router Address"
                            type="text"
                            fullWidth
                            value={routerForm.routerAddress}
                            variant="standard"
                            onChange={setRouterOnChangeForm}
                        />
                        <FormControl fullWidth={true} sx={{marginTop: 2, minWidth: 200}}>
                            <InputLabel id="select-chain-id-label">Chain</InputLabel>
                            <Select
                                labelId="select-chain-id-label"
                                id="select-chain-id"
                                value={routerForm.chainId}
                                onChange={handleSelectedChainInRouter}
                                fullWidth={true}
                                label="Chain"
                            >
                                {routerContext.chainList.map((chainOption) => <MenuItem
                                    value={chainOption.id}>{chainOption.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth={true} sx={{marginTop: 2, minWidth: 200}}>
                            <InputLabel id="select-chain-id-label">Chain</InputLabel>
                            <Select
                                labelId="select-swap-version-label"
                                id="select-swap-version"
                                name={"swapVersion"}
                                value={routerForm.swapVersion.toString()}
                                onChange={handleSelect}
                                fullWidth={true}
                                label="Swap Version"
                            >
                                <MenuItem
                                    value={SwapVersion.V2}>V2</MenuItem>
                                <MenuItem
                                    value={SwapVersion.V3}>V3</MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsRouterDialogOpen(false)}>Cancel</Button>
                        <Button onClick={() => saveRouter()}>Save</Button>
                    </DialogActions>
                </Dialog>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Router Name</TableCell>
                                <TableCell>Router Address</TableCell>
                                <TableCell>Chain Name</TableCell>
                                <TableCell>Swap Version</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {routerContext.routerList.map((router) => (
                                <TableRow
                                    key={router.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {router.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {router.routerAddress}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {routerContext.chainList.find(x => x.id == router.chainId)?.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {router.swapVersion}
                                    </TableCell>
                                    <TableCell>
                                        <Switch onChange={(event) => handleEnableSwitchRouter(event, router.id)} checked={router.isEnabled}/>
                                    </TableCell>
                                    <TableCell>
                                        <Button variant={"contained"} style={{backgroundColor: "green"}}
                                                onClick={() => editRouter(router)}>Edit</Button><br/>
                                        <Button variant={"contained"} style={{backgroundColor: "red"}}
                                                onClick={() => deleteRouter(router.id)}>Delete</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </AccordionDetails>
        </Accordion>
        <BotSettingsAccordion/>
    </Container>
}

export default SettingsPage