import React, {useState} from 'react';
import './App.css';
import {useAuth0} from "@auth0/auth0-react";
import MainPage from "./components/snipers";
import AppBar from "./components/app-bar";
import {Route, Routes, useRoutes} from "react-router";
import DashboardPage from "./components/dashboard";
import ProtectedRoute from "./components/auth/protected-route";
import TransactionsPage from "./components/transactions";
import SnipersPage from "./components/snipers";
import TokenPage from "./components/token";
import {Toaster} from "react-hot-toast";
import Button from "@mui/material/Button";
import WalletPage from "./components/wallet";
import ProfilePage from "./components/profile";
import {WalletContextProvider} from "./components/provider/wallet-context-provider";
import SettingsPage from "./components/settings";
import {RouterContextProvider} from "./components/provider/router-context-provider";

function App() {

    const auth = useAuth0();

    return (
        <div className="App">
            <Toaster/>
            <AppBar auth={auth}/>
            {auth.isAuthenticated ?
                <WalletContextProvider>
                    <RouterContextProvider>
                        <Routes>
                            <Route index element={<MainPage/>}/>
                            <Route path="dashboard" element={
                                <ProtectedRoute>
                                    <DashboardPage/>
                                </ProtectedRoute>
                            }/>
                            <Route path="snipers" element={
                                <ProtectedRoute>
                                    <SnipersPage/>
                                </ProtectedRoute>
                            }/>
                            <Route path="transactions" element={
                                <ProtectedRoute>
                                    <TransactionsPage/>
                                </ProtectedRoute>
                            }/>
                            <Route path="token" element={
                                <ProtectedRoute>
                                    <TokenPage/>
                                </ProtectedRoute>
                            }/>
                            <Route path="wallet" element={
                                <ProtectedRoute>
                                    <WalletPage/>
                                </ProtectedRoute>
                            }/>
                            <Route path="settings" element={
                                <ProtectedRoute>
                                    <SettingsPage/>
                                </ProtectedRoute>
                            }/>
                            <Route path="profile" element={
                                <ProtectedRoute>
                                    <ProfilePage/>
                                </ProtectedRoute>
                            }/>
                        </Routes>
                    </RouterContextProvider>
                </WalletContextProvider> :
                <Button onClick={() => auth.loginWithRedirect()} color="inherit">Login</Button>}
        </div>
    );
}

export default App;
