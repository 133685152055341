import {useEffect, useState} from "react";
import {RouterContext} from "../../context";
import useApiClient from "../../hooks/useApiClient";
import {ChainList, RouterList} from "../../types/dto";

export const RouterContextProvider = ({children}: any) => {
    const [routerOptions, setRouterOptions] = useState<any[]>([]);
    const [chainList, setChainList] = useState<ChainList[]>([]);
    const [routerList, setRouterList] = useState<RouterList[]>([]);
    const apiClient = useApiClient();

    useEffect(() => {
        refreshChainList().then(x => {
            refreshRouterList(x);
        });
    }, [])

    const refreshChainList = () => {
        return apiClient.getChainList().then(x => {
            setChainList(x)
            return x;
        })
    }

    const refreshRouterList = (chains: ChainList[]) => {
        apiClient.getRouterList().then(routers => {
            var routerOptions = routers.map(router => {
                var defaultPairing = chains.find(x => x.id == router.chainId)
                return {
                    isEnabled: router.isEnabled,
                    chainId: router.chainId,
                    label: router.name,
                    value: router.id,
                    defaultPairing: defaultPairing?.nativeTokenAddress
                }
            })
            routerOptions.unshift({label: '', value: '', defaultPairing: '', chainId: '', isEnabled: false},)
            setRouterOptions(routerOptions)
            setRouterList(routers)
        })
    }

    return <RouterContext.Provider value={{
        routerOptions: routerOptions,
        routerList,
        chainList,
        refreshRouter: refreshRouterList,
        refreshChain: refreshChainList
    }}>{children}</RouterContext.Provider>
}