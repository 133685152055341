import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import useApiClient from "../../hooks/useApiClient";
import {
    Alert, Autocomplete, Checkbox, Chip, createFilterOptions, Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl, FormControlLabel,
    InputLabel, OutlinedInput,
    Select, SelectChangeEvent,
    TextField, useTheme
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {getStyles, routerOptionInitialState} from "../token";
import {useWalletContext} from "../../hooks/use-wallet-context";
import {InstantBuyRequestModel, SnipingListDto, SnipingMethod} from "../../types/dto";
import toast from "react-hot-toast";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";
import {useRouterContext} from "../../hooks/use-router-context";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const filter = createFilterOptions<SnipingMethod>();

const initialState = {
    methodHashes: [],
    pairAddress: '',
    shouldSnipeAddLiq: false,
    amountToBuy: 0,
    targetTokenAddress: '',
    isForceBuy: false,
    createdTimestamp: null
}

const SnipersPage = () => {
    const [snipeList, setSnipeList] = useState<SnipingListDto[]>([]);
    const [snipeListFormDetails, setSnipeListFormDetails] = useState<any>(initialState);
    const [isSnipeListFormOpen, setIsSnipeListFormOpen] = useState(false);
    const [selectedRouter, setSelectedRouter] = useState<{label: string, value: string, defaultPairing: string}>(routerOptionInitialState);
    const [walletToBuySelection, setWalletToBuySelection] = useState<string[]>([])
    const [walletAddressList, setWalletAddressList] = useState<string[]>([]);
    const [methodHashesOptions, setMethodHashesOptions] = useState<SnipingMethod[]>([]);
    const [methodHashesValue, setMethodHashesValue] = useState<any>([])
    const apiClient = useApiClient();
    const theme = useTheme();
    const walletContext = useWalletContext();
    const [newValueDialogOpen, setIsNewValueDialogOpen] = useState(false);
    const [methodToAdd, setMethodToAdd] = useState<SnipingMethod>({
        methodName: '',
        methodHash: ''
    });
    const routerContext = useRouterContext();

    useEffect(() => {
        const walletList = walletContext.walletList.map(x => x.walletAddress);
        setWalletAddressList(walletList)
    }, [walletContext.walletList])

    useEffect(() => {
        refreshSnipingList();
        refreshMethodHashOption();
    }, [])

    const refreshMethodHashOption = () => {
        apiClient.getSnipingMethodOptions().then(x => {
            setMethodHashesOptions(x);
        });
    }

    const refreshSnipingList = () => {
        apiClient.getSnipingList().then(x => {
            setSnipeList(x);
        });
    }

    const onChangePreBuy = async (event: any) => {
        setSnipeListFormDetails({
            ...snipeListFormDetails,
            [event.target.name]: event.target.value
        })
    }

    const handleChecked = async (event: any) => {
        setSnipeListFormDetails({
            ...snipeListFormDetails,
            [event.target.name]: event.target.checked
        })
    }

    const handleSelectedChain = (value: any) => {
        if (value == null){
            setSelectedRouter(routerOptionInitialState);
            return;
        }

        setSelectedRouter(value);
        setSnipeListFormDetails((prevState: any) => ({
            ...prevState,
            pairAddress: value.defaultPairing
        }));
    };

    const handleSelectChange = (event: any) => {
        const {
            target: {value},
        } = event;
        setWalletToBuySelection(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const setFormMethodHashesValue = (values: SnipingMethod[]) => {
        const uniqueValues = values.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.methodHash === value.methodHash
                ))
        )

        setMethodHashesValue(uniqueValues);
        setSnipeListFormDetails((prevState: any) => ({
            ...prevState,
            methodHashes: uniqueValues.map((x: SnipingMethod) => x.methodHash)
        }));
    }

    const onInputMethodToAddChange = async (event: any) => {
        setMethodToAdd((prevState: any) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }

    const handleAddMethodHash = () => {
        apiClient.addSnipingMethod({
            methodHash: methodToAdd?.methodHash,
            methodName: methodToAdd?.methodName
        }).then(x => {
            if (!x) {
                return;
            }
            refreshMethodHashOption();
            setIsNewValueDialogOpen(false);
            toast.success("Method hash has been succesfully added.")
        })
    }

    const handleFormSubmit = (event: any) => {
        event.preventDefault();
        var chainId = routerContext.routerList.find(x => x.id == selectedRouter.value)!.chainId;

        apiClient.addSnipingList({
            routerId: selectedRouter.value,
            chain: chainId,
            methodHashes: snipeListFormDetails.methodHashes,
            pairAddress: snipeListFormDetails.pairAddress,
            walletAddress: walletToBuySelection,
            isSnipingAddLiq: snipeListFormDetails.shouldSnipeAddLiq,
            isForceBuy: snipeListFormDetails.isForceBuy,
            snipeAmount: snipeListFormDetails.amountToBuy,
            targetContractAddress: snipeListFormDetails.targetTokenAddress,
            createdTimestamp: snipeListFormDetails.createdTimestamp,
            slippagePercentage: snipeListFormDetails.slippagePercentage
        }).then(x => {
            if (!x) {
                toast.error("Failed to add sniping list.")
                return;
            }

            setIsSnipeListFormOpen(false);
            refreshSnipingList()
            toast.success("Sniping list has been succesfully added.")
        })
    }

    const handleSync = () => {
        apiClient.syncSnipingList().then(x => {
            if (!x) {
                toast.error("Failed to sync sniping list.")
                return;
            }

            toast.success("Sniping list has been succesfully synced.")
        })
    }

    const handleEdit = (snipeList: SnipingListDto) => {
        setSnipeListFormDetails({
            methodHashes: snipeList.methodHashes,
            pairAddress: snipeList.pairAddress,
            shouldSnipeAddLiq: snipeList.isSnipingAddLiq,
            amountToBuy: snipeList.snipeAmount,
            targetTokenAddress: snipeList.targetContractAddress,
            isForceBuy: snipeList.isForceBuy,
            createdTimestamp: snipeList.createdTimestamp,
            slippagePercentage: snipeList.slippagePercentage
        });
        setSelectedRouter(routerContext.routerOptions.find(x => x.value == snipeList.routerId));
        setWalletToBuySelection(snipeList.walletAddress)
        setIsSnipeListFormOpen(true);
    }

    const handleDeleteSnipingList = (createdTimestamp: number) => {
        apiClient.deleteSnipingList(createdTimestamp).then(x => {
            if (!x) {
                toast.error("Failed to delete sniping list.")
                return;
            }

            refreshSnipingList();
            toast.success("Sniping list has been succesfully deleted.")
        })
    }

    const onBuyClick = (sniperToken: SnipingListDto) => {
        var instantBuyRequest : InstantBuyRequestModel = {
            chain: sniperToken.routerId,
            amountToBuy: sniperToken.snipeAmount,
            amountTokenToBuy: "0",
            targetTokenAddress: sniperToken.targetContractAddress,
            walletAddresses: sniperToken.walletAddress,
            pairAddress: sniperToken.pairAddress,
            slippagePercentage: sniperToken.slippagePercentage
        }

        apiClient.instantBuy(instantBuyRequest).then(x => {
            if (!x) {
                toast.error("Buy token order failed")
                return;
            }
            toast.success("Buy token order succesfull")
        })
    }

    return <Container maxWidth={false}>
        <Box component="span"
             style={{color: "white"}}
             m={1}
             display="flex"
             justifyContent="space-between"
             alignItems="center">
            Sniping List
            <Button onClick={() => handleSync()} variant={"contained"}>Sync Snipe</Button>

            <Button onClick={() => {
                setSnipeListFormDetails(initialState)
                setIsSnipeListFormOpen(true)
                }
            } variant={"contained"}>Add token to
                snipe</Button>
        </Box>
        <Box>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">Contract Address</TableCell>
                            <TableCell align="right">Pair Address</TableCell>
                            <TableCell align="right">Path</TableCell>
                            <TableCell align="right">Chain</TableCell>
                            <TableCell align="right">Router</TableCell>
                            <TableCell align="right">Method Hashes</TableCell>
                            <TableCell align="right">Is Sniping Add Liq</TableCell>
                            <TableCell align="right">Is Force Buy</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            <TableCell align="right">Wallet Address</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {snipeList.map((token: any) => (
                            <TableRow
                                key={token.contractAddress}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell align="right">{token.targetContractAddress}</TableCell>
                                <TableCell align="right">{token.pairAddress}</TableCell>
                                <TableCell align="right">{token.path?.map((x: string) => <Chip label={x}/>)}</TableCell>
                                <TableCell
                                    align="right">{routerContext.chainList.find(x => x.id == token.chain)?.name}</TableCell>
                                <TableCell
                                    align="right">{routerContext.routerList.find(x => x.id == token.routerId)?.name}</TableCell>
                                <TableCell align="right">{token.methodHashes?.map((x: string) => <Chip
                                    label={x}/>)}</TableCell>
                                <TableCell align="right">{token.isSnipingAddLiq ? <CheckBoxIcon/> :
                                    <CancelIcon/>}</TableCell>
                                <TableCell align="right">{token.isForceBuy ? <CheckBoxIcon/> :
                                    <CancelIcon/>}</TableCell>
                                <TableCell align="right">{token.snipeAmount}</TableCell>
                                <TableCell align="right">{token.walletAddress?.map((x: string) => <Chip
                                    label={x}/>)}</TableCell>
                                <TableCell>
                                    <Button style={{background: "blue"}}
                                            onClick={() => onBuyClick(token)}
                                            variant={"contained"}>Buy</Button>
                                    <Button style={{background: "green"}}
                                            onClick={() => handleEdit(token)}
                                            variant={"contained"}>Edit</Button>
                                    <Button style={{background: "red"}}
                                            onClick={() => handleDeleteSnipingList(token.createdTimestamp)}
                                            variant={"contained"}>Delete</Button>

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>

        <Dialog open={isSnipeListFormOpen} onClose={() => {
        }}>
            <form onSubmit={handleFormSubmit}>
                <DialogTitle>Add Sniping List (Add Liq / Function)</DialogTitle>
                <DialogContent>
                    {(snipeListFormDetails?.unknownWallets != undefined && snipeListFormDetails.unknownWallets > 0) &&
                        <Alert severity="error">There are some unknown wallet int his pre buy settings, please save to
                            sync wallet !!<br/>
                            Unknown Wallet List
                            <ul>
                                {snipeListFormDetails.unknownWallets.map((address: string) => <li>{address}</li>)}
                            </ul>
                        </Alert>}
                    <DialogContentText>
                        Setup your pre configured add liq to hit when token launch or function called !! 🚀
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="targetTokenAddress"
                        name="targetTokenAddress"
                        label="Target Address"
                        type="text"
                        fullWidth
                        onChange={onChangePreBuy}
                        value={snipeListFormDetails?.targetTokenAddress}
                        variant="standard"
                    />
                    <FormControl fullWidth={true} sx={{m: 1, minWidth: 80}}>
                        <Autocomplete
                            onChange={(event, newValue) => handleSelectedChain(newValue)}
                            id="router-select"
                            value={selectedRouter}
                            options={routerContext.routerOptions}
                            renderOption={(props, option) =>
                                <li {...props}>{option.label}</li>}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Router" />}
                        />
                    </FormControl>
                    <br/>
                    <FormControl fullWidth={true} sx={{m: 1, minWidth: 80}}>
                        <InputLabel id="wallet-address-select">Wallet Address</InputLabel>
                        <Select
                            labelId="wallet-address-select"
                            id="select-multiple-wallet-address"
                            multiple
                            value={walletToBuySelection}
                            onChange={handleSelectChange}
                            input={<OutlinedInput id="select-multiple-wallet-address" label="Wallet Address"/>}
                            renderValue={(selected) => (
                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value}/>
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {walletAddressList.map((address) => (
                                <MenuItem
                                    key={address}
                                    value={address}
                                    style={getStyles(address, walletToBuySelection, theme)}
                                >
                                    {address}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth={true} sx={{m: 1, minWidth: 80}}>

                        <Autocomplete
                            multiple={true}
                            value={methodHashesValue}
                            onChange={(event, newValue) => {
                                if (newValue && newValue.some(x => x.methodName == "ToAdd")) {
                                    setTimeout(() => {
                                        setIsNewValueDialogOpen(true);
                                    });
                                } else {
                                    setFormMethodHashesValue(newValue)
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                if (params.inputValue !== '') {
                                    filtered.push({
                                        methodHash: `Add "${params.inputValue}"`,
                                        methodName: "ToAdd",
                                    });
                                }

                                return filtered;
                            }}
                            id="free-solo-dialog-demo"
                            options={methodHashesOptions}
                            getOptionLabel={(option) => {
                                if (option == undefined) {
                                    return option;
                                }
                                // e.g value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.methodHash;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            renderOption={(props, option) =>
                                <li {...props}>{option.methodHash} {option.methodName}</li>}
                            sx={{width: 300}}
                            freeSolo
                            renderInput={(params) => <TextField {...params} label="Method Hashes"/>}
                        />
                        <Dialog open={newValueDialogOpen} onClose={() => setIsNewValueDialogOpen(false)}>
                            <form>
                                <DialogTitle>Add a new method to snipe</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Please add method hash and method name
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="methodName"
                                        name="methodName"
                                        label="Method Name"
                                        type="text"
                                        onInput={onInputMethodToAddChange}
                                        variant="standard"
                                    />
                                    <TextField
                                        margin="dense"
                                        id="methodHash"
                                        name="methodHash"
                                        label="Method Hash"
                                        type="text"
                                        onInput={onInputMethodToAddChange}
                                        variant="standard"
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setIsNewValueDialogOpen(false)}>Cancel</Button>
                                    <Button onClick={handleAddMethodHash}>Add</Button>
                                </DialogActions>
                            </form>
                        </Dialog>
                    </FormControl>
                    <FormControlLabel control={<Checkbox
                        id="shouldSnipeAddLiq"
                        name="shouldSnipeAddLiq"
                        onChange={handleChecked}
                        checked={snipeListFormDetails?.shouldSnipeAddLiq ?? false}
                    />} label="Should Snipe Add Liq?" labelPlacement={"start"}/>

                    <FormControlLabel control={<Checkbox
                        id="isForceBuy"
                        name="isForceBuy"
                        onChange={handleChecked}
                        checked={snipeListFormDetails?.isForceBuy ?? false}
                    />} label="Force Buy?" labelPlacement={"start"}/>

                    <TextField
                        margin="dense"
                        id="pairAddress"
                        name="pairAddress"
                        label="Pair Address"
                        type="text"
                        fullWidth
                        onChange={onChangePreBuy}
                        value={snipeListFormDetails?.pairAddress}
                        variant="standard"
                    />
                    <TextField
                        margin="dense"
                        id="amountToBuy"
                        name="amountToBuy"
                        label="Amount"
                        type="text"
                        onChange={onChangePreBuy}
                        value={snipeListFormDetails?.amountToBuy}
                        fullWidth
                        variant="standard"
                    />

                    <TextField
                        margin="dense"
                        id="slippagePercentage"
                        name="slippagePercentage"
                        label="Slippage Percentage"
                        type="number"
                        onChange={onChangePreBuy}
                        value={snipeListFormDetails?.slippagePercentage}
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setIsSnipeListFormOpen(false)}>Cancel</Button>
                    <Button onClick={handleFormSubmit}>Save</Button>
                </DialogActions>
            </form>
        </Dialog>
    </Container>
}

export default SnipersPage