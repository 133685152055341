import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, InputLabel, Select, SelectChangeEvent, Switch, Table,
    TextField
} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import React, {useEffect, useState} from "react";
import {SnipingBotSettings, SnipingBotSettingsRequest} from "../../types/dto";
import {useRouterContext} from "../../hooks/use-router-context";
import useApiClient from "../../hooks/useApiClient";
import toast from "react-hot-toast";

export const BotSettingsAccordion = () => {

    const [IsDialogOpen, setIsDialogOpen] = useState(false);
    const [snipingBotSettingsForm, setSnipingBotSettingsForm] = useState<SnipingBotSettingsRequest>({
        name: '',
        id: '',
        chainId: '',
        isEnabled: false
    });
    const [botSettingsList, setBotSettingsList] = useState<SnipingBotSettings[]>([]);

    const routerContext = useRouterContext();
    const apiClient = useApiClient();

    useEffect(() => {
        refreshBotSettingsList()
    }, [])

    const refreshBotSettingsList = () => {
        apiClient.getBotSettingsList().then(x => {
            setBotSettingsList(x)
        });
    }

    const handleSelect = (event: SelectChangeEvent) => {
        setSnipingBotSettingsForm((prevState: any) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    };

    const handleEnableSwitch = (event: React.ChangeEvent<HTMLInputElement>, settings: SnipingBotSettings) => {
        apiClient.saveBotSettings({
            isEnabled: event.target.checked,
            name: settings.name,
            chainId: settings.chainSettings.chainId,
            id: settings.id
        }).then(x => {
            refreshBotSettingsList()
        })
    }

    const handleOnChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setSnipingBotSettingsForm({
            ...snipingBotSettingsForm,
            [ev.target.name]: ev.target.value
        })
    }

    const handleEdit = (settings: SnipingBotSettings) => {
        setSnipingBotSettingsForm({
            chainId: settings.chainSettings?.chainId,
            id: settings.id,
            name: settings.name,
            isEnabled: settings.isEnabled
        });
        setIsDialogOpen(true)
    }

    const handleSave = () => {
        apiClient.saveBotSettings(snipingBotSettingsForm).then(response => {
            if (!response) {
                toast.error("Failed to update bot settings")
                return;
            }
            refreshBotSettingsList();
            toast.success("Bot Settings successfully updated")
        })

        setIsDialogOpen(false)
    }

    return <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMore/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography>Sniping Bot Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Dialog open={IsDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle>Update Sniping Bot Settings</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bot Name: {snipingBotSettingsForm?.name}
                    </DialogContentText>

                    <FormControl fullWidth={true} sx={{marginTop: 2, minWidth: 200}}>
                        <InputLabel id="select-chain-id-label">Chain</InputLabel>
                        <Select
                            labelId="select-chain-id-label"
                            id="select-chain-id"
                            value={snipingBotSettingsForm.chainId}
                            name={"chainId"}
                            onChange={handleSelect}
                            fullWidth={true}
                            label="Chain"
                        >
                            {routerContext.chainList.map((chainOption) => <MenuItem
                                value={chainOption.id}>{chainOption.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow >
                            <TableCell>Bot Name</TableCell>
                            <TableCell>Rpc Url</TableCell>
                            <TableCell>Websocket Url</TableCell>
                            <TableCell>Is Enabled?</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {botSettingsList.map((botSettings) => (
                            <TableRow
                                key={botSettings.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {botSettings.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {botSettings.chainSettings.rpcUrl}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {botSettings.chainSettings.wsUrl}
                                </TableCell>
                                <TableCell><Switch onChange={(event) => handleEnableSwitch(event, botSettings)} checked={botSettings.isEnabled}/></TableCell>
                                <TableCell>
                                    <Button variant={"contained"} style={{backgroundColor: "green"}}
                                            onClick={() => handleEdit(botSettings)}>Edit</Button><br/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </AccordionDetails>
    </Accordion>
}