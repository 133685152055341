import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {Auth0ContextInterface, useAuth0} from "@auth0/auth0-react";
import {Link, useNavigate} from "react-router-dom";
import Logo from '../assets/image/religionfy-logo.png'
import {Image} from "@mui/icons-material";
const pages = [{
    name: 'Dashboard',
    route: 'dashboard'
}, {name: 'Transactions', route: 'transactions'},
    {name: 'Snipers', route: 'snipers'}, {name: 'Token', route: 'token'}];

const PrimonterAppBar = ({auth}: { auth: Auth0ContextInterface }) => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    //@ts-ignore
    const roles = auth?.user != null ? auth?.user['https://religionfy.com//roles'] : [];

    const settings = [
        {
            menuName: 'Profile',
            onClick: () => navigate("profile")
        },
        {
            menuName: 'Wallet',
            onClick: () => navigate("wallet")
        },
        {
            menuName: 'Logout',
            onClick: () => auth.logout({
                logoutParams: {
                    returnTo: window.location.origin
                }
            })
        }];

    if (roles?.some((x: any) => x == "Admin")){
        settings.unshift({
            menuName: 'Settings',
            onClick: () => navigate("settings")
        })
    }


    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {auth.isAuthenticated ? <>
                        <img src={Logo} />

                        {/*<Typography*/}
                        {/*    variant="h6"*/}
                        {/*    noWrap*/}
                        {/*    component="a"*/}
                        {/*    sx={{*/}
                        {/*        mr: 2,*/}
                        {/*        display: {xs: 'none', md: 'flex'},*/}
                        {/*        fontFamily: 'monospace',*/}
                        {/*        fontWeight: 700,*/}
                        {/*        letterSpacing: '.3rem',*/}
                        {/*        color: 'inherit',*/}
                        {/*        textDecoration: 'none',*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {process.env.REACT_APP_TITLE}*/}
                        {/*</Typography>*/}
                        <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {xs: 'block', md: 'none'},
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page.name}>
                                        <Link to={page.route}>
                                            <Typography textAlign="center">{page.name}</Typography>
                                        </Link>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <AttachMoneyIcon sx={{display: {xs: 'flex', md: 'none'}, mr: 1}}/>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            sx={{
                                mr: 2,
                                display: {xs: 'flex', md: 'none'},
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            {process.env.REACT_APP_TITLE}
                        </Typography>
                        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                            {pages.map((page) => (
                                <Link to={page.route}>
                                    <Button
                                        key={page.name}
                                        sx={{my: 2, color: 'white', display: 'block'}}
                                    >
                                        {page.name}
                                    </Button>
                                </Link>
                            ))}
                        </Box>
                        <Box sx={{flexGrow: 0}}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg"/>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{mt: '45px'}}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting.menuName} onClick={() => setting.onClick()}>
                                        <Typography textAlign="center">{setting.menuName}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </> : <></>}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default PrimonterAppBar;