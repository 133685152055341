import {useAuth0} from "@auth0/auth0-react";
import {ReactNode} from "react";

type Props = {
    children: ReactNode
}
const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const auth = useAuth0();

    if (!auth.isAuthenticated && !auth.isLoading) {
        auth.loginWithRedirect();
        return <></>
    }

    return children;
};

export default ProtectedRoute